import siteLogoImg from "../pictures/logo.png";

export const siteName = "TULEDATA"; //this will display on sidebar
export const smsName = "tuledata"; // for bulk sms sending on behalf of the bussiness
export const appMainColor = "#200647"; // app's primary color
export const siteLogo = siteLogoImg; // site logo
export const baseApiUrl = "https://api.foudhan.com/tuledata/"; // for api call
export const contactNumber = "07037100479";
export const contactEmail = "support@tuledata.com.ng";
export const contactAddress = "Sabon Layi Funtua, Katsina State.";
export const appname = "tuledataapp.apk";
export const appApkUrl = baseApiUrl + "download/" + appname;
export const whatsappChat = "https://wa.me/+2347037100479";
export const whatsappGroup =
  "https://whatsapp.com/channel/0029Vb2f3W8I1rcfHP5Uqf3A";
export const monnifyApiParams =
  "MK_TEST_LRPY0VY5Q0:WJ7FGVFZ2P5PWD4LD2ZUVWG8FU137SLQ";
